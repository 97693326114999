<template>
	<one-card>
		<template #one>
			<GenericList
				title="variáveis"
				ref="lista"
				data-table-state-name="Variavel"
				@edicao-variavel="mostrarEdicao"
				:cols="colunas"
				:cols-name="colunas"
				:linhas="linhas"
			/>
			<b-modal ref="modal" hide-footer title="Gestão de variável" >
				<AddEditVariavel
					:itemId="itemId"
					@cancelar="cancelar"
					@salvar-edicao="salvarModal"
					@salvar-adicao="salvarModal"
					@remover-variavel="remover"
				/>
			</b-modal>
		</template>
	</one-card>
</template>
	<script>
	import OneCard from "../templates/OneCard.vue";
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";

	import VueMultiSelect from "vue-multi-select";
	import "vue-multi-select/dist/lib/vue-multi-select.css";

	import GenericList from "@/components/GenericList";
	import AddEditVariavel from "@/components/Edit.vue";

	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import { TrendsService } from "@/services/trends";
	
	let updateInterval;

	export default {
		components: {
			OneCard,
			VueMultiSelect,
			GenericList,
			AddEditVariavel,
			Datepicker
		},

		/*props: {
			update: {
				type: Function
			}
		},*/

		data () {
			return {
				ptBR,
				itemId: 0,
				grupos: [],
				varSelecionadas: [],
				movSelecionados: [],
				viagensSelecionadas: [],
				
				variaveis: [],
				movimentos: [],
				viagens: [],

				tagInput: "",
				descricaoInput: "",

				loading: false,

				lista: [],

				gruposSelecionados: [],
				gruposOpcoes: [],

				areaSelecionada: [],
				areasOpcoes: [],

				maquinasSelecionadas: [],
				maquinasOpcoes: [],

				tiposVariaveis: [],
				tiposOpcoes: [],

				multiSelectFilters: [
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true),
					this.getMultiSelectFilters(true)
				],
				FILTROS_RAPIDOS: [
					{
						nome: "Último dia",
						inicio: [-1, "day"],
						fim: [0, "days"],
						agrupamento: "Hora",
						intervalo: 300
					},
					{
						nome: "Última semana",
						inicio: [-1, "week"],
						fim: [0, "weeks"],
						agrupamento: "Hora",
						intervalo: 1800
					},
					{
						nome: "Último mês",
						inicio: [-1, "month"],
						fim: [0, "months"],
						agrupamento: "Dia",
						intervalo: 3600
					}
				],

				filtros: {
					inicio: null,
					fim: null,
					agrupamento: null,
					rapido: 0
				},
				trendsService: new TrendsService()
			};
		},

		computed: {
			linhas () {
				return this.lista.map(row => ({
					href: { path: row.id },
					cols: Object.values(row)
				}));
			},
			colunas () {
				try {
					return Object.keys(JSON.parse(JSON.stringify(this.lista))[0]);
				} catch {
					return [];
				}

			}
		},

		async mounted () {
			this.filtroRapido(this.FILTROS_RAPIDOS[0]);
			this.movSelecionados = [];

			function uniqByKeepFirst(a, key) {
				let seen = new Set();
				return a.filter(item => {
					let k = key(item);
					return seen.has(k) ? false : seen.add(k);
				});
			}

			this.movimentos = await this.trendsService.getData(["movimento"],10, 20, 0)
			this.movimentos = this.movimentos.map(el => ({'nome': el.movimento,disabled:false}))
			this.movimentos = uniqByKeepFirst(this.movimentos, it => it.nome);

			this.viagens = await this.trendsService.getData(["viagem"],10, 20, 0)
			this.viagens = this.viagens.map(el => ({'nome': el.viagem,disabled:false}))
			this.viagens = uniqByKeepFirst(this.viagens, it => it.nome);
			
			this.variaveis = [{nome: "viagem", disabled:true},
				{nome: "composicao", disabled:true},
				//{nome: "movimento", disabled:false},
				{nome: "placa", disabled:false},
				{nome: "erro", disabled:false},
				{nome: "camera", disabled:false},
				//{nome: "balanca", disabled:false},
				//{nome: "qtd_composicoes", disabled:false},
				{nome: "sujidade_composicao", disabled:false},
				{nome: "sujidade_caminhao", disabled:false},
				{nome: "data_hora", disabled:false},
				{nome: "url_resultado", disabled:false},
				{nome: "url_original", disabled:false},];
			this.varSelecionadas = this.variaveis

			this.errosSelecionados = [{nome: "balança não monitorada", disabled:false}];
			this.erros = [{nome: "balança não monitorada", disabled:false}]
			
			this.update();
			clearInterval(updateInterval);
			updateInterval = setInterval(() => {
				this.update();
			}, 5000);
		},

		methods: {
			async update () {
				try {
					this.loading = true;
					const inicio = dayjs(this.filtros.inicio).startOf("day").toDate();
					const fim = dayjs(this.filtros.fim).endOf("day").toDate();
					let filters={"movimento":this.optionsToList(this.movSelecionados),"viagem":this.optionsToList(this.viagensSelecionadas),"erros":this.optionsToList(this.errosSelecionados)};
					this.lista = await this.trendsService.getData(this.optionsToList(this.varSelecionadas),filters,inicio, fim, 20);
					this.loading = false;
				} catch (err) {
					console.log(err);
					this.$swal({
						title: "Falha ao fazer a busca!",
						html: `<p>${err.response ? err.response.data.error : err.message}</p>`,
						confirmButtonText: "Fechar",
						confirmButtonColor: "#6c757d"
					});
				}
			},

			fecharModal () {
				this.$refs.modal.hide();
			},

			mostrarEdicao (itemId) {
				this.itemId = itemId;
				this.$refs.modal.show();
			},

			mostrarAdicao () {
				this.itemId = 0;
				this.$refs.modal.show();

				this.fecharModal();
			},

			cancelar () {
				this.loading = false;
				this.fecharModal();
			},

			salvarModal () {
				this.loading = true;

				this.filtroVariaveis();
				this.fecharModal();
			},

			async remover () {
				this.filtroVariaveis();
				this.fecharModal();
			},

			filtroRapido (fr) {
				this.filtros.rapido = fr;
				if (fr.inicio)
					this.filtros.inicio = dayjs()
						.add(...fr.inicio)
						.toDate();

				if (fr.fim)
					this.filtros.fim = dayjs()
						.add(...fr.fim)
						.toDate();
			},

			btnFiltroRapido (fr) {
				if (this.filtros.rapido !== fr) this.filtroRapido(fr);
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			getMultiSelectFilters (feminino) {
				return [{
					nameAll: `Selecionar tod${feminino ? "a" : "o"}s`,
					nameNotAll: `Desselecionar tod${feminino ? "a" : "o"}s`,
					func () {
						return true;
					}
				}];
			},
			optionsToList (options) {
				return options.map(el => el.nome);
			}
		}
	};
	</script>
